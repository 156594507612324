
































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { getDateWithDifference } from '@/api/v3/DataGenerator'
import VueI18n from 'vue-i18n'
import * as actionTypes from '@/store/actionTypes'
import ModalHeader from './components/ModalHeader.vue'
import PreviewStep from './components/PreviewStep.vue'
import type { InfoTFA, CodeWithInfoTFA } from '@/store/modules/profile/types'
import type CodeInputStep from './components/CodeInputStep.vue'
import { format } from 'quasar'
import { teamsStore } from '@/store'

const { capitalize } = format

/**
 * Constant with steps numbers
 * @see PreviewStep.vue for 0
 * @see PasswordStep.vue for 1
 * @see EmailStep.vue for 2
 * @see CodeInputStep.vue for 3
 * @see SuccessStep.vue for 4
 */
const steps = {
  Preview: 0,
  CreatePass: 1,
  Email: 2,
  Code: 3,
  Success: 4,
}

@Component({
  name: 'CreatePassword',
  components: {
    PreviewStep,
    ModalHeader,
    PasswordStep: () => import('./components/PasswordStep.vue'),
    SuccessStep: () => import('./components/SuccessStep.vue'),
    EmailStep: () => import('./components/EmailStep.vue'),
    CodeInputStep: () => import('./components/CodeInputStep.vue'),
  },
})
export default class CreatePassword extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  readonly isOpened!: boolean

  $refs!: {
    CodeInput: CodeInputStep;
  }

  step = steps.Preview

  loading = false
  canNext = false

  pass = ''
  repeatPass = ''
  hint = ''
  passLabel = '' // in created value
  passErr = false

  email = ''
  emailErr = ''

  emailCode = ''
  emailCodeLength = 4
  emailCodeErr = ''

  codeTimeout = -1
  codeInterval: number | null = null

  headers: VueI18n.TranslateResult[] = []

  created () {
    this.email = teamsStore.getters.currentTeam.me.contactEmail
    this.headers = [
      this.$t('auth.twoFactorAuth.title'),
      this.$t('auth.twoFactorAuth.passStepTitle'),
      this.$t('auth.twoFactorAuth.mailStepTitle'),
      this.$t('auth.twoFactorAuth.codeInputTitle'),
      this.$t('auth.twoFactorAuth.passEstablished'),
    ]
  }

  /**
   * Do not show while there is code input step
   */
  get showControls (): boolean {
    return this.step !== steps.Code || !this.loading
  }

  get isPassValid (): boolean {
    return this.pass.length > 0 && this.repeatPass.length > 0
  }

  get btnDisable (): boolean {
    if (this.step === steps.Email && this.email.trim() === '') return true
    return !this.isPassValid && !this.passErr
  }

  // we do not need to show Next btn in steps with Code and Success Steps
  get showOnlyBackBtn (): boolean {
    return this.step < steps.Code
  }

  get backBtnLabel (): string {
    return this.step === steps.Success
      ? this.$t('auth.twoFactorAuth.backToSettings').toString()
      : this.$t('common.back').toString()
  }

  // show top-case stepper steps only for PASS, EMAIL and CODE
  // don't need to show steps in Preview and Success
  get showStepper (): boolean {
    return this.step >= steps.CreatePass && this.step <= steps.Code
  }

  get nextText (): string {
    return capitalize(this.$t('common.next').toString())
  }

  passInput (value: string) {
    this.pass = value
    this.passErr = false
    this.passLabel = ''
  }

  repeatInput (value: string) {
    this.repeatPass = value
  }

  hintInput (value: string) {
    this.hint = value
  }

  emailInput (value: string) {
    this.email = value
    this.emailErr = ''
  }

  /**
   * Close modal
   */
  close () {
    this.$emit('close')
  }

  /**
   * Confirm email by code from component
   * @param value @see CodeInputStep.vue
   */
  async codeInput (value: string) {
    this.loading = true
    this.emailCode = value
    this.emailCodeErr = ''
    try {
      await this.$store.dispatch(actionTypes.TFA_CONFIRM_EMAIL, {
        password: this.pass,
        email: this.email,
        code: this.emailCode,
      })
      this.step = steps.Success
    } catch (e) {
      this.emailCodeErr = e?.details?.code ?? e.error
      this.$refs.CodeInput.focus()
    }
    this.loading = false
  }

  /**
   * Steps control function
   * @see steps
   */
  async nextStep () {
    if (this.step === steps.Preview) {
      this.step = steps.CreatePass
    } else if (this.step === steps.CreatePass) {
      this.loading = true
      try {
        await this.$store.dispatch(actionTypes.TFA_CREATE_PASS, {
          newPass: this.pass,
          repeatPass: this.repeatPass,
          hint: this.hint,
        })
        this.step = steps.Email
      } catch (e) {
        if (e.details) {
          this.passErr = true
          this.passLabel = Object.values(e.details)[0] ?? e.error
        } else {
          this.passLabel = e.error
        }
      }
      this.loading = false
    } else if (this.step === steps.Email) {
      await this.setEmail()
    } else if (this.step === steps.Success) {
      this.close()
    }
  }

  /**
   * Set email or without email
   * @param withEmail confirm email or decline and continue without email
   */
  async setEmail (withEmail = true) {
    this.loading = true
    this.emailErr = ''
    this.emailCodeErr = ''
    const payload: { password: string; email?: string } = {
      password: this.pass,
      email: withEmail ? this.email : undefined,
    }

    try {
      /**
       * it can technically return InfoTFA, but not in this case
       * TODO: refactor! two actions or overloads
       */
      const r: CodeWithInfoTFA | InfoTFA = await this.$store.dispatch(
        actionTypes.TFA_SET_EMAIL,
        payload,
      )
      if (withEmail) {
        this.step = steps.Code
        const typedR: CodeWithInfoTFA = r as CodeWithInfoTFA
        this.emailCodeLength = typedR.codeLength ?? 4

        this.codeTimeout = (
          new Date(typedR.nextCodeAt).getTime() -
          getDateWithDifference('ts')
        ) / 1000

        this.codeInterval =
          this.codeInterval ||
          setInterval(() => {
            if (this.codeTimeout >= 0) {
              this.codeTimeout--
            } else {
              this.codeInterval && clearInterval(this.codeInterval)
              this.codeInterval = null
            }
          }, 1000)
      } else {
        this.step = steps.Success
      }
    } catch (e) {
      this.emailErr = e.details?.email ?? e.error
      this.emailCodeErr = e.details?.email ?? e.error
    }
    this.loading = false
  }

  /**
   * Prev step or close modal if it's endless step
   */
  prevStep () {
    if (this.step === steps.Success) this.close()
    this.step--
  }
}




















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'ModalHeader',
  components: {
    IconTimes: () => import('@/components/UI/icons/IconTimes.vue'),
  },
})
export default class ModalHeader extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  readonly header!: string
}

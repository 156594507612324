import { render, staticRenderFns } from "./ModalHeader.vue?vue&type=template&id=af564124&scoped=true&"
import script from "./ModalHeader.vue?vue&type=script&lang=ts&"
export * from "./ModalHeader.vue?vue&type=script&lang=ts&"
import style0 from "./ModalHeader.vue?vue&type=style&index=0&id=af564124&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af564124",
  null
  
)

export default component.exports
import {QIcon,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QIcon})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})



















import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'PreviewStep',
  components: {
    BasePictureWithText: () => import('./BasePictureWithText.vue'),
  },
})
export default class PreviewStep extends Vue {}
